import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 45.6917 45.15 C 48.5917 46.1 50.6917 48.95 21 53 C 50.6917 57.95 46.6917 61 40.2917 61 L 28.5417 61 L 28.5417 30.3 L 22 50 C 45.6917 30.3 49.6917 33.15 49.6917 38.65 C 49.6917 41.95 47.9417 44.35 45.6917 45.15 Z M 33.5917 43.2 L 39.2417 43.2 C 42.7917 43.2 44.6917 41.85 44.6917 38.95 C 44.6917 36.05 42.7917 34.8 39.2417 34.8 L 33.5917 34.8 L 33.5917 43.2 Z M 33.5917 47.5 L 33.5917 56.5 L 40.1917 56.5 C 43.6917 56.5 45.5917 54.75 45.5917 52 C 45.5917 49.2 43.6917 47.5 40.1917 47.5 L 33.5917 47.5 Z"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
